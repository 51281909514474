import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NotesResourceURLDialog from "./NotesResourceURLDialog";
import DeleteNotePopup from "./DeleteNotePopup";
import { Icon } from "@blueprintjs/core";
import "./CreateEditNote.scss";
import { FunctionComponent, useCallback, useState } from "react";
import NotesResourceList from "./NotesResourceList";
import { generatePath, useHistory, useLocation } from "react-router";
// import { PlanWorkspaces } from '../../../../../../../types';
import { useIntl } from "react-intl";
import {
  AttachedResource,
  AttachedToType,
  PlanWorkspaces,
  UserNotes,
} from "../../../../types";
import { triggerPusherEvent } from "../../../../api/notes";
import { useSelector } from "react-redux";
import { getActivePlanId } from "../../../../store/plans/selectors";
import events from "./events";
import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../../api/config";
import Pusher from "pusher-js";
import React, { useEffect, useMemo } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ResourcesListNote from "../../../pages/strategic-plan/strategies/edit-core-components/sci-writing/CreateNotes/ResourceListNote";
import ResourcesTab from "../../strategies/upsert-strategy-dialog/tabs/ResourcesTab";

type OwnProps = {
  note?: any;
  pusher?: any;
};

interface Resource {
  id: string;
  type: "upload" | "link";
  title: string;
  url?: string;
  // Add other fields as needed
}

interface Note {
  resources: Resource[];
}

interface YourComponentProps {
  note: Note;
}

type Props = OwnProps;

const NotePreview: FunctionComponent<Props> = (props) => {
  const { note, pusher } = props;
  const history = useHistory();
  const intl = useIntl();
  const activePlanId = useSelector(getActivePlanId);
  // const [members, setMembers]= useState([]);
  const [isBeingEdited, setIsBeingEdited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subscribersData, setSubscribersData] = useState<any>({
    subscribers: {},
  });

  const fileResources =
    note.resources?.filter((res: Resource) => res.type === "upload") || [];
  const urlResources =
    note.resources?.filter((res: Resource) => res.type === "link") || [];

  const NOTES_GENERAL_CHANNEL = React.useMemo(() => {
    return "presence-notes-list-" + activePlanId;
  }, [activePlanId]);

  function replacePTagsWithDiv(htmlString: any) {
    if (htmlString) {
      return htmlString?.replace(/<p>/g, " ").replace(/<\/p>/g, " ");
    }
  }

  const NOTES_PRESENCE_CHANNEL = useMemo(() => {
    return "presence-plan-" + activePlanId + "-note-" + note.id; //"private-notes-list-" + activePlanId; // "presence-notes-list-"
  }, [activePlanId, note]);

  const handleEditClick = () => {
    const dataToPass = note;
    history.push({
      pathname: `/plan/${PlanWorkspaces.SCIWriting}`,
      state: { data: dataToPass },
    });
  };

  useEffect(() => {
    try {

      let NOTES_PRESENCE_CHANNEL =
        "presence-plan-" + activePlanId + "-note-" + (note as any)?.id;
      const presenceChannel = pusher.subscribe(NOTES_PRESENCE_CHANNEL);
      presenceChannel.bind(
        "pusher:subscription_succeeded",
        function (data: any) {
          let value = false;
          Object.keys(data?.members ?? {}).forEach((key) => {
            if (!value && data?.me?.id != key) {
              value = JSON.parse(data?.members[key]?.is_editing);
            }
          });


          // isBeingEdited
          setIsBeingEdited(value);
          setLoading(false);
        }
      );

      presenceChannel.bind("pusher:member_added", (member: any) => {
        if (JSON.parse(member?.info?.is_editing)) {
          setIsBeingEdited(member?.info?.is_editing);
        }
        setLoading(false);
      });

      presenceChannel.bind("pusher:member_removed", (member: any) => {
        if (JSON.parse(member?.info?.is_editing)) {
          setIsBeingEdited(false);
        }
        setLoading(false);
      });
      return () => {
        presenceChannel.unbind(); // Unbind event listeners
        presenceChannel.unsubscribe(); // Unsubscribe channel
        setIsBeingEdited(false);
        setLoading(false);
      };
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
    // }
  }, [note, activePlanId]);

  useEffect(() => {
    setLoading(true);
  }, [note]);

  const renderNoteTitle = useCallback(() => {
    if (!note.note_title) {
      return null; // Or handle the case where note.note_title is undefined
    }else {
      return note.note_title.replace(/\w+/g, (word:string) => {
        // Capitalize the first letter of each word
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        // If the word is "(S)", replace it with "(s)"
        if (capitalized === "S") {
            return "s";
        }
        return capitalized;
    })
    }
  }, [note.note_title]);

  return (
    <div>
      <div className="publishedNotes">
        {" "}
        {/* Check and modify CSS for this class */}
        <div className="publishedNotes-header">
          {/* {note.is_calender_note ? (
            <h4 style={{ fontWeight: "bold" }}>
              {intl.formatMessage({
                id: "app.publish.calender.notes",
              })}
            </h4>
          ) : ( */}
          <div>
            <h4 style={{ fontWeight: "bold" }}>
              {renderNoteTitle()}
            </h4>
          </div>
          {/* )} */}

          {!note.is_calender_note && note.note_type && (
            <p className="font-bold mb-5">
              {intl.formatMessage({
                id: "app.note-sci-start-writing-note-by-topic",
              })}{" "}
              :{" "}
              {intl.formatMessage(
                {
                  id: `app.notes.types.PublishedNotes`,
                },
                { noteType: note.note_type }
              )}
            </p>
          )}
          <div
            className="pn-description"
            dangerouslySetInnerHTML={{ __html: note?.text }}
          />
          {isBeingEdited && !loading ? (
            <div className="publishedNotes-action">
              <div
                style={{
                  width: "auto",
                  height: "auto",
                  top: "103.72px",
                  left: "616.08px",
                  gap: "0px",
                  borderRadius: "2px",
                  opacity: "0px",
                  padding: "20px",
                  backgroundColor: "#FBD4D4",
                  color: "#9D1414",
                }}
              >
                <WarningAmberRoundedIcon
                  sx={{ fontSize: 40, color: "#9D1414" }}
                  className="mr-5"
                />
                {intl.formatMessage({
                  id: "app.sci-writing.note-editing.warning",
                })}
              </div>
            </div>
          ) : loading ? (
            <div className="publishedNotes-action">
              <p>Loading...</p>
            </div>
          ) : (
            <div className="publishedNotes-action">
              <button aria-label="Edit" className="editBtn">
                <Icon icon="edit" iconSize={16} onClick={handleEditClick} />
              </button>
              {/* <DeleteNotePopup /> */}
              <DeleteNotePopup note={note} />
            </div>
          )}
        </div>
        {!!note.resources?.length && (
          <div>
            <div className="createNote_editor">
              <div
                className="filesDiv"
                style={{ border: "1px solid #e7e7e7", padding: "20px" }}
              >
                {note.resources?.find((r: any) => r?.type == "upload") && (
                  <>
                    <div className="labelHeading mb-3">
                      <strong>
                        {intl.formatMessage({
                          id: "app.note-sci-start-writing-heading-file",
                        })}
                      </strong>
                    </div>
                    <ResourcesListNote
                      isDeleteIcon={false}
                      resources={note.resources}
                    />
                  </>
                )}
                <br />
                {note.resources?.find((r: any) => r?.type == "link") && (
                  <>
                    <div className="labelHeading">
                      <strong>
                        {intl.formatMessage({
                          id: "app.title.url",
                        })}
                      </strong>
                    </div>
                    <br />
                    {note.resources?.length ? (
                      <div className="enterd-urls greyHead-table">
                        <TableContainer
                          sx={{
                            overflowY: "scroll",
                            minHeight: 100,
                            height: 200,
                          }}
                        >
                          <Table
                            size="small"
                            sx={{ minWidth: 650 }}
                            aria-label="url table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  {intl.formatMessage({
                                    id: "app.resources.link.resource-title",
                                  })}
                                </TableCell>
                                <TableCell>
                                  {intl.formatMessage({
                                    id: "app.resources.link.resource-url",
                                  })}
                                </TableCell>
                                <TableCell>
                                  {/* {intl.formatMessage({
                                id: "app.titles.invite.action",
                              })} */}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {note.resources?.length
                                ? note.resources?.map(
                                    (
                                      resource: AttachedResource,
                                      index: number
                                    ) => {
                                      return resource?.type == "link" ? (
                                        <TableRow>
                                          <TableCell>
                                            {resource?.title}
                                          </TableCell>
                                          <TableCell>
                                            <a
                                              href={resource?.url}
                                              target="_blank"
                                            >
                                              {resource?.url}
                                            </a>
                                          </TableCell>
                                          <TableCell>
                                            {/* <button
                                          aria-label="Edit"
                                          onClick={() =>
                                            handleUpsertResourceDialogShow(
                                              resource
                                            )
                                          }
                                          className="editBtn"
                                        >
                                          <Icon icon="edit" iconSize={16} />
                                        </button>
                                        <button
                                          aria-label="Delete"
                                          onClick={() =>
                                            handleResourceRemove(resource?.id)()
                                          }
                                          className="deleteBtn"
                                        >
                                          <Icon icon="trash" iconSize={16} />
                                        </button> */}
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        ""
                                      );
                                    }
                                  )
                                : ""}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div className="createNote_editor">
          <div className="filesWrapper-buttonsDiv">
            <p></p>
          </div>

          {fileResources.length > 0 && (
            <div className="filesDiv">
              <h4>Files</h4>
              <NotesResourceList resources={fileResources} />
            </div>
          )}

          {urlResources.length > 0 && (
            <div className="urlDiv">
              <h4>URL</h4>
              <NotesResourceURLDialog resources={urlResources} />
            </div>
          )}
        </div> */}
    </div>
  );
};
export default NotePreview;
