import { Icon, Tag } from "@blueprintjs/core";
import React from "react";
import {
  AttachedResource,
  AttachedResourceType,
} from "../../../../../../../../src/types";
import { emptyString } from "react-select/src/utils";

type OwnProps = {
  resources: AttachedResource[];
  onResourceEdit?: (res: AttachedResource) => () => void;
  onResourceRemove?: (resource: AttachedResource) => () => void;
  clickable?: boolean;
  noData?: React.ReactNode;
  lastElement?: React.ReactNode;
  isDeleteIcon?: Boolean;
};

type Props = OwnProps;

const ResourcesListNote: React.FC<Props> = (props) => {
  const {
    resources,
    onResourceEdit,
    onResourceRemove,
    clickable = true,
    noData,
    children,
    lastElement,
    isDeleteIcon = true,
  } = props;

  const handleResourceClick = async (res: AttachedResource) => {
    const isLink = res.type === AttachedResourceType.Link;

    try {
      if (isLink) {
        const url = res.url ?? res.attachment_url; 
        // Open the URL in a new tab
        return window.open(url, "_blank", "noopener,noreferrer");
      }

      const response = await fetch(res.url ?? res.attachment_url ?? ""); 
      if (!response.ok) throw new Error("Network response was not ok");

      // Convert the response to a Blob
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = res.title; // Specify the desired file name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Download failed", error);
    }
  };

  const handleRemoveClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    res: AttachedResource
  ) => {
    e.stopPropagation();
    !!onResourceRemove && onResourceRemove(res)();
  };

  const getFileExtension = (fileName: any) => {
    return fileName.split(".").pop();
  };
  const getFileIconClass = (fileExtension: any) => {
    switch (fileExtension.toLowerCase()) {
      case "doc":
        return "doc-icon";
      case "docx":
        return "docx-icon";
      case "pdf":
        return "pdf-icon";
      case "jpg":
        return "jpg-icon";
      case "png":
        return "png-icon";
      case "csv":
        return "csv-icon";
      case "xlsx":
        return "xlsx-icon";
      case "ppt":
        return "ppt-icon";
      case "pptx":
        return "pptx-icon";
      case "hyperlink":
        return "hyperlink-icon";
      default:
        return "jpg-icon"; // Default class or no class for unknown extensions
    }
  };

  return (
    <div
      className="flex gap-2"
      style={{
        // width: "600px",
        overflowX: resources.length ? "scroll" : "unset",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {children}
      {resources.length
        ? resources.map((res) => {
            const fileExtension = getFileExtension(res.title);
            const iconClass = `filesName_Chips ${getFileIconClass(
              fileExtension
            )}`;
            if (res?.type === "upload")
              return (
                <div
                  className="filesWrapper-uploadedFiles"
                  key={res.id}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {res?.type === "upload" && (
                   <div className={iconClass} style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                   {getFileIconClass(fileExtension) === "xlsx-icon" && (
                     <div style={{ display: "flex", alignItems: "center" }}>
                       <svg
                         style={{ width: "24px", height: "24px" }} // Adjust size as needed
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 50 50"
                       >
                         <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path>
                         <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path>
                         <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path>
                         <path fill="#17472a" d="M14 24.005H29V33.055H14z"></path>
                         <g>
                           <path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path>
                           <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"></path>
                           <path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path>
                           <path fill="#129652" d="M29 24.005H44V33.055H29z"></path>
                         </g>
                         <path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path>
                         <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
                       </svg>
                     </div>
                   )}
                 
                   <span
                     onDoubleClick={!!onResourceEdit ? onResourceEdit(res) : undefined}
                     onClick={(e) => {
                       e.stopPropagation();
                       clickable && handleResourceClick(res);
                     }}
                     style={{ overflow: "hidden", textOverflow: "ellipsis", flexGrow: 1 }}
                   >
                     {res.title}
                   </span>
                 
                   {isDeleteIcon && (
                     <button
                       className="chipDelete_Btn"
                       onClick={(e) => handleRemoveClick(e, res)}
                       style={{ marginLeft: "8px" }}
                     >
                       <Icon icon="cross" iconSize={14} />
                     </button>
                   )}
                 </div>
                 
                 
                  )}
                </div>
              );
          })
        : noData}
      {lastElement}
    </div>
  );
};

export default ResourcesListNote;
