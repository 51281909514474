import React from "react";
import { Icon } from "@blueprintjs/core";
import Dialog from "@mui/material/Dialog";
import "./infoBar.scss";
import { useIntl } from "react-intl";
import { Link, generatePath, useHistory } from "react-router-dom";

import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../../store/plans/selectors";

function InfoBar() {
  const [infoexpanded, infosetExpanded] = React.useState<string | false>(false);
  const [infoDocument, setInfoDocument] = React.useState<string | false>(false);

  const intl = useIntl();
  const isActiveDistrictPlan = useSelector(isDistrictPlan);
  const history = useHistory();

  const infohandleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    infosetExpanded(isExpanded ? panel : false);
  };

  const [infobaropen, infoBarOpen] = React.useState(false);

  const infoBarClickOpen = () => {
    infoBarOpen(true);
  };

  const infoBarClose = () => {
    infoBarOpen(false);
    infosetExpanded(false);
    setInfoDocument(false);
  };

  return (
    <>
      <button onClick={infoBarClickOpen}>
     <span className="flex">
       <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M8.22291 16.5977C12.6345 16.5977 16.2236 13.0087 16.2236 8.59766C16.2236 4.18663 12.6345 0.597656 8.22291 0.597656C3.81129 0.597656 0.222168 4.18604 0.222168 8.59766C0.222168 13.0093 3.81129 16.5977 8.22291 16.5977ZM8.22291 1.88993C11.9217 1.88993 14.931 4.89921 14.931 8.59729C14.931 12.2957 11.9217 15.3046 8.22291 15.3046C4.52409 15.3046 1.51481 12.2961 1.51481 8.59729C1.51481 4.89851 4.52409 1.88993 8.22291 1.88993Z"
                    fill="#195A94"
                  />
                  <path
                    d="M8.22298 6.97778C7.86563 6.97778 7.57666 7.26733 7.57666 7.62411V12.9819C7.57666 13.3387 7.86563 13.6283 8.22298 13.6283C8.58034 13.6283 8.86931 13.3387 8.86931 12.9819V7.62411C8.86931 7.26733 8.58034 6.97778 8.22298 6.97778Z"
                    fill="#195A94"
                  />
                  <path
                    d="M9.14815 5.1378C9.14815 5.6485 8.7341 6.06256 8.22325 6.06256C7.71239 6.06256 7.29834 5.6485 7.29834 5.1378C7.29834 4.62694 7.71239 4.21289 8.22325 4.21289C8.7341 4.21289 9.14815 4.62694 9.14815 5.1378Z"
                    fill="#195A94"
                  />
                </svg>
                 Info
      </span>
      </button>
      <Dialog
        open={infobaropen}
        onClose={infoBarClose}
        className="sidebar-modal small-modal text-base"
      >
        <div className="sidepanel">
          <div className="sidepanel__header">
            <h3>
              {intl.formatMessage({
                id: "app.titles.information.resources.strategic-plan",
              })}
            </h3>
            <button onClick={infoBarClose}>
              <Icon icon="cross" iconSize={12} />
            </button>
          </div>
          <div className="sidepanel__content">
            <div className="info-list">
              {isActiveDistrictPlan ?<ul>
                <li>
                <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.coordinating-district-wide-survey-administration"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.coordinating-district-wide-survey-administration"})}
                  </a>
                </li>

                <li>
                <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.tools-for-analyzing-data-across-schools"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.tools-for-analyzing-data-across-schools"})}
                  </a>
                </li>


                <li>
                <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.approaches-to-analyzing-district-wide-data"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.approaches-to-analyzing-district-wide-data"})}
                  </a>
                </li>


                <li>
                <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.additional-considerations-while-analyzing-districtwide-data"}))}>
                    <span>
                      <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                      </span>
                    {intl.formatMessage({id:"app.title.additional-considerations-while-analyzing-districtwide-data"})}
                  </a>
                </li>

                

                </ul> :<ul>
                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.overview-nj-sci-survey"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.overview-nj-sci-survey"})}
                  </a>
                </li>

                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.informing-community-members-of-nj-sci-survey"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.informing-community-members-of-nj-sci-survey"})}
                  </a>
                </li>


                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.preparing-for-survey-administration"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.preparing-for-survey-administration"})}
                  </a>
                </li>

                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.monitoring-ongoing-nj-sci-survey-administration"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.monitoring-ongoing-nj-sci-survey-administration"})}
                  </a>
                </li>

                <li>
                  <a onClick={()=>setInfoDocument(intl.formatMessage({id:"app.title.how-district-staff-can-support-survey-administration"}))}>
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="13px"
                      height="17px"
                      viewBox="0 0 13 17"
                      enable-background="new 0 0 13 17"
                    >
                      <path
                        fill="#619CD1"
                        d="M0.03,1.064v15.068c0,0.162,0.064,0.317,0.179,0.432c0.114,0.115,0.27,0.18,0.432,0.18h11.403
                    c0.161,0,0.317-0.064,0.433-0.18c0.113-0.114,0.178-0.27,0.178-0.432V5.137c0-0.162-0.064-0.317-0.179-0.432L8.402,0.649l0,0
                    c-0.1-0.1-0.23-0.161-0.37-0.175H0.641c-0.158,0-0.311,0.062-0.425,0.172C0.102,0.755,0.035,0.906,0.03,1.064L0.03,1.064z
                    M8.582,2.537l1.994,1.989H8.582V2.537z M1.251,1.675H7.36v3.461c0,0.162,0.064,0.317,0.179,0.432
                    c0.115,0.114,0.27,0.179,0.432,0.179h3.463v9.774H1.251V1.675z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.114,7.581h4.073v1.222H2.114V7.581z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,10.146h8.107v1.223H2.133V10.146z"
                      />
                      <path
                        fill="#619CD1"
                        d="M2.133,12.835h6.701v1.223H2.133V12.835z"
                      />
                    </svg>
                    {intl.formatMessage({id:"app.title.how-district-staff-can-support-survey-administration"})}
                  </a>
                </li>
                
              </ul>}

              {/* <div className="teamPopup_footer">
                <button>
                  <svg className="calenderIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M16.8428 2.24868H14.6019V0.575684H13.4814V2.24868H4.51778V0.575684H3.39733V2.24868H1.15642C1.00786 2.24868 0.865299 2.30744 0.760258 2.412C0.655217 2.51656 0.596191 2.65847 0.596191 2.80635V16.748C0.596191 16.8959 0.655217 17.0378 0.760258 17.1424C0.865299 17.2469 1.00786 17.3057 1.15642 17.3057H16.8428C16.9913 17.3057 17.1339 17.2469 17.2389 17.1424C17.344 17.0378 17.403 16.8959 17.403 16.748V2.80635C17.403 2.65847 17.344 2.51656 17.2389 2.412C17.1339 2.30744 16.9913 2.24868 16.8428 2.24868ZM3.39733 3.36402V4.47935H4.51778V3.36402H13.4814V4.47935H14.6019V3.36402H16.2826V5.59468H1.71665V3.36402H3.39733ZM1.71665 16.1903V6.71002H16.2826V16.1903H1.71665Z" fill="#155B98" />
                    <path d="M3.11768 8.66162H5.35858V9.77695H3.11768V8.66162Z" fill="#155B98" />
                    <path d="M6.29297 8.66162H8.53388V9.77695H6.29297V8.66162Z" fill="#155B98" />
                    <path d="M9.46484 8.66162H11.7058V9.77695H9.46484V8.66162Z" fill="#155B98" />
                    <path d="M12.6421 8.66162H14.883V9.77695H12.6421V8.66162Z" fill="#155B98" />
                    <path d="M3.11768 10.8926H5.35858V12.0079H3.11768V10.8926Z" fill="#155B98" />
                    <path d="M6.29297 10.8926H8.53388V12.0079H6.29297V10.8926Z" fill="#155B98" />
                    <path d="M9.46484 10.8926H11.7058V12.0079H9.46484V10.8926Z" fill="#155B98" />
                    <path d="M12.6421 10.8926H14.883V12.0079H12.6421V10.8926Z" fill="#155B98" />
                    <path d="M3.11768 13.123H5.35858V14.2384H3.11768V13.123Z" fill="#155B98" />
                    <path d="M6.29297 13.123H8.53388V14.2384H6.29297V13.123Z" fill="#155B98" />
                    <path d="M9.46484 13.123H11.7058V14.2384H9.46484V13.123Z" fill="#155B98" />
                    <path d="M12.6421 13.123H14.883V14.2384H12.6421V13.123Z" fill="#155B98" />
                  </svg> 
                  
                  <span 
                  className="linkView" 
                   onClick={()=>history.push(
                    generatePath(`/plan/team/${isActiveDistrictPlan ? 'dclt_sci_' : 'sclt_sci_'}calendar` as any),)}
                  >{intl.formatMessage({id:"app.titles.go-to-sci-calender"})}</span></button>

                <button 
                 onClick={()=>history.push(
                  generatePath(`/plan/team/${isActiveDistrictPlan ? 'dclt_resources' : 'sclt_resources'}` as any),)}
                ><Icon iconSize={18} icon="layers" /> 
                <span 
                className="linkView" 
                >{intl.formatMessage({id:"app.titles.resources"})} </span></button>
              </div> */}
            </div>
          </div>
        </div>

        {
          infoDocument ===
          intl.formatMessage({id:"app.title.overview-nj-sci-survey"}) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>{" "}
                  {intl.formatMessage({id:"app.title.overview-nj-sci-survey"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({id:"app.title.overview-nj-sci-survey-content"},
                  {
                    p: (chunks) => <p>{chunks}</p>,
                    b: (chunks) => <b>{chunks}</b>,
                    i: (chunks) => <i>{chunks}</i>,
                    br: <br />,
                    a: (chunks) => (
                      <a
                        href="https://platform.njschoolclimate.org/files/activity/ODc=/download/pdf/NJ+SCI+Survey+Flier.pdf"
                        target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    ),
                    st:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MjAw/download/pdf/New+Jersey+School+Climate+Improvement+Survey+Validation+Report+2022-2023.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    )

                  })}
              </div>
            </div>
          )}

        {
          infoDocument ===
          intl.formatMessage({
            id:
              "app.title.informing-community-members-of-nj-sci-survey",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.title.informing-community-members-of-nj-sci-survey",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.title.informing-community-members-of-nj-sci-survey-content" },
                  {
                    i: (chunks) => <i>{chunks}</i>,
                    br:<br />,
                    a:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MjU=/download/pdf/Community+Engagement+in+the+SCCP.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    )


                  })}
              </div>
            </div>
          )}

        {
          infoDocument ===
          intl.formatMessage({
            id: "app.title.preparing-for-survey-administration",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.title.preparing-for-survey-administration",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.title.preparing-for-survey-administration-content" }
                  , {
                    i: (chunks) => <i>{chunks}</i>,
                    ul: (chunks) => <ul>{chunks}</ul>,
                    li: (chunks) => <li>{chunks}</li>,
                    br:<br />,
                    bullet: <Icon
                    iconSize={15}
                    icon={"dot"}
                    className="bg-white text-black "
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle", // Aligns icon with the baseline
                      marginRight: "2px", // Adds spacing between bullet and text
                    }}
                  />,
                    a:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MTE=/download/pdf/NJ+SCI+Survey+Administration+Guidelines+For+Data+Coordinators+Sept+2024.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    ),
                    a2:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MTI=/download/pdf/NJ+SCI+Survey+Administration+Guidelines+for+Staff+Administering+to+Students+Sept+2024.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    )

                  })}
              </div>
            </div>
          )}

        {
          infoDocument ===
          intl.formatMessage({
            id:
              "app.title.monitoring-ongoing-nj-sci-survey-administration",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.title.monitoring-ongoing-nj-sci-survey-administration",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.title.monitoring-ongoing-nj-sci-survey-administration-content" },
                  {
                    br: <br />,
                    b: (chunks) => <b>{chunks}</b>,
                    i: (chunks) => <i>{chunks}</i>,

                  })}

              </div>
            </div>
          )}

          {
          infoDocument ===
          intl.formatMessage({id:  "app.title.how-district-staff-can-support-survey-administration"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:  "app.title.how-district-staff-can-support-survey-administration"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" dangerouslySetInnerHTML={{__html:intl.formatMessage({id:  "app.title.how-district-staff-can-support-survey-administration-content"})}}>
              </div>
            </div>
          )}

{
          infoDocument ===
          intl.formatMessage({
            id:
              "app.title.coordinating-district-wide-survey-administration",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.title.coordinating-district-wide-survey-administration",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.title.coordinating-district-wide-survey-administration-content" },
                  {
                    br: <br />,
                    b: (chunks) => <b>{chunks}</b>,
                    i: (chunks) => <i>{chunks}</i>,
                    a:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MzY=/download/pdf/Coordinating+District-wide+NJ+SCI+Survey+Administration.pdf"
                      target="_blank"
                      className="text-blue-500 dark:text-blue-600 hover:underline"

                      >
                         {chunks}
                      </a>
                    )
                  })}

              </div>
            </div>
          )}

{
          infoDocument ===
          intl.formatMessage({
            id:
              "app.title.tools-for-analyzing-data-across-schools",
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.title.tools-for-analyzing-data-across-schools",
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.title.tools-for-analyzing-data-across-schools-content" },
                  {
                    br: <br />,
                  })}

              </div>
            </div>
          )}

{
          infoDocument ===
          intl.formatMessage({
            id:
              "app.title.approaches-to-analyzing-district-wide-data"
,
          }) && (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({
                    id:
                      "app.title.approaches-to-analyzing-district-wide-data"
,
                  })}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content">
                {intl.formatMessage({ id: "app.title.approaches-to-analyzing-district-wide-data-content" },
                  {
                    br: <br />,
                    i: (chunks) => <i>{chunks}</i>,
                    ul: (chunks) => <ul>{chunks}</ul>,
                    li: (chunks) => <li>{chunks}</li>,
                    a:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/NTA=/download/pdf/Guidance+for+Supporting+Data+Analysis+as+a+DCLT+%281%29.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    ),
                    bullet: <Icon
                    iconSize={15}
                    icon={"dot"}
                    className="bg-white text-black "
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle", // Aligns icon with the baseline
                      marginRight: "2px", // Adds spacing between bullet and text
                    }}
                  />
                  })}

              </div>
            </div>
          )}

{
          infoDocument ===intl.formatMessage({id:"app.title.additional-considerations-while-analyzing-districtwide-data"})&& (
            <div className="sidepanel info-panel-detail">
              <div className="sidepanel__header">
                <h3>
                  <button onClick={() => setInfoDocument(false)}>
                    <Icon icon="arrow-left" iconSize={12} />
                  </button>
                  {intl.formatMessage({id:"app.title.additional-considerations-while-analyzing-districtwide-data"})}
                </h3>
                <button onClick={infoBarClose}>
                  <Icon icon="cross" iconSize={12} />
                </button>
              </div>
              <div className="sidepanel__content" >
               
              {intl.formatMessage({ id: "app.title.additional-considerations-while-analyzing-districtwide-data-content"},
                  {
                    br: <br />,
                    i: (chunks) => <i>{chunks}</i>,
                    a:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MTI5/download/pdf/Communicating+about+School-Level+Data+%28Prior+to+Setting+Goals%29.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    ),
                    a2:(chunks)=>(
                      <a 
                      href="https://platform.njschoolclimate.org/files/activity/MTMx/download/pdf/Data+Sharing+Options+Resource.pdf"
                      target="_blank"
                        className="text-blue-500 dark:text-blue-600 hover:underline"
                      >
                        {chunks}
                      </a>
                    ),
                    bullet: <Icon
                    iconSize={15}
                    icon={"dot"}
                    className="bg-white text-black "
                    style={{
                      display: "inline-block",
                      verticalAlign: "middle", // Aligns icon with the baseline
                      marginRight: "2px", // Adds spacing between bullet and text
                    }}
                  />
                  })}
              </div>
            </div>
          )}    



      </Dialog>
    </>
  );
}

export default InfoBar;