import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Tab, Tabs, Popover, Checkbox, Icon,Button } from "@blueprintjs/core";
import AddAdditionalDataForm from "./AddAdditionalDataForm";
import { Helmet } from "react-helmet";
import SurveyDeploymentDialog from "../../survey-deployment/dialogs/SurveyDeploymentDialog";
import CreateCustomDataSetDialog from "./create-custom-data-set-dialog/CreateCustomDataSetDialog";
import SurveyDataTab from "./SurveyDataTab";
import DistrictComparisonTab from "./DistrictComparisonTab";
import SurveyUpsertDialog from "../../surveys/dialogs/SurveyUpsertDialog";
import AdministrationGuidelinesTab from "./administration-guidelines-tab/AdministrationGuidelinesTab";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import {
  ActivityTagEnum,
  UserNotesType,
  DistrictComparisonRouteParams,
  Survey,
  SurveyDeploymentStateEnum,
  TeamRole,
  SurveyTableItem,
  School,
  DomainQuestionsComparison,
  DomainComparison,
  SurveyDemographic,
  SurveyReportTabEnum,
  ActivityScopeEnum,
} from "../../../types";
import CreateDatasetFromFileDialog from "./create-dataset-from-file-dialog/CreateDatasetFromFileDialog";
import ActivityResourcesTab from "../members/tabs/resources-tab/ActivityResourcesTab";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { useSelector } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../../constants";
import CoordinateSurveyAdministrationDialog from "../members/tabs/team-home-tab/CoordinateSurveyAdministrationDialog";
import ReturnButton from "../../surveys/cards/ReturnButton";
import { getUrlParam } from "../../../helpers/url-utils";
import { InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from '@mui/material/FormControl';

import { useDispatch } from "react-redux";
import { getSurveys, setSelectedSurvey } from "../../../store/surveys/actions";
import { showConfirmDialog,hideConfirmDialog } from "../../../store/UIState/actions";
import { getSurveysDeployment, showSurveyDeploymentDialog } from "../../../store/surveys-deployment/actions";
import moment from "moment";
import SurveysDeploymentTable from "../../survey-deployment/table/surveys-deployment-table/SurveysDeploymentTable";
import { SURVEYS_DEPLOYMENT_TABLE } from "../../../constants/constants";


import * as XLSX from "xlsx";
import { domain } from "process";
import { getSurveyDemographicForComparison } from "../../surveys/tab/useComparison";
import _ from "lodash";
import { isDomainQuestionsComparison } from "../../surveys/table/utils";
import Axios from 'axios';
import { API_ROOT } from "../../../api/config";
import { getComparisonDomainItemsData } from "../../../store/survey-reports/actions";
import { getActivities, showActivityVideoDialog } from "../../../store/activities/actions";
import InfoBar from "./collect-data-infobar/infoBar";

type OwnProps = {};

type Props = OwnProps;

export const REPORT_TYPE_PARAM = "report_type";

const CollectDataPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const history = useHistory();

  const location = useLocation();
  const dispatch = useDispatch();
 

  const [
    isExistSameSchoolYearSD,
    setIsExistSameSchoolYearSD,
  ] = useState<boolean>(false);

  const [selectedYears, setSelectedYears] = useState<any[]>([]);
  const [selectedSchoolDeployment, setSelectedSchoolDeployment] = useState<any[]>([]);
  const [isGenerateReportOnly,setIsGenerateReportOnly]=useState<boolean>(false);

  const surveys = useSelector((s) => s.surveys.surveys);
  
  const activePlan = useSelector((s) => s.plans.activePlan);
  const loading = useSelector((s) => s.surveys.loading.getSurveys);
  const activities = useSelector((s) => s.activities.activities);

  useEffect(() => {
    if (activePlan) {
      dispatch(
        getActivities.request({
          type:isDistrictActivePlan
          ? ActivityScopeEnum.DISTRICT
          : ActivityScopeEnum.SCHOOL,
          tag: ActivityTagEnum.DATA,
        })
      );
    }
  }, [ activePlan]);
  const handleShowScreencastTutorialDialog = () => {
    // dispatch(showScreencastTutorialDialog(true));
    const searchString = "Screencast Tutorial of NJ SCI Survey Deployment";
    const activity: any = activities.find((item) => {
      return item.name === searchString;
    });

    dispatch(
      showActivityVideoDialog({
        activity: activity,
        viewable_field: "embed_code",
      })
    );
  };

  const sdLoading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveysDeployment
  );

  const surveysDeployments = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );

  const addSD = useSelector(
    (s) => s.surveysDeployment.loading.addSurveyDeployment
  );



  const routeParams = useParams<DistrictComparisonRouteParams>();
  const selectedDomain = useSelector((s) => s.domains.selectedDomain);
  const { tabId=UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS } = useParams<{ tabId?: string }>();
  const {hasDataCoordinatorAccess,teamRole, user } = useUserRole();
 

  const surveyComparison:any = useSelector(
    (s) => s.surveyReports.districtComparison
  );


  const isTeamMember = useMemo(()=>{
    return teamRole ? teamRole === TeamRole.TeamMember : false;
  },[teamRole]);

 
  const sortedArr = activePlan?.school?.grades?.sort((a, b) => {
    const numA = parseInt(a.slice(1));  // Extract the number after 'G'
    const numB = parseInt(b.slice(1));  // Extract the number after 'G'
    return numA - numB;  // Sort in ascending order
  });


  const getCurrentSession = () => {
    const SURVEY_MONTH: number = 6;
    const date = moment(new Date());

    if (parseInt(moment(date).format("M")) > SURVEY_MONTH) {
      return `${moment(date).format("YYYY")}-${moment(date)
        .add(1, "years")
        .format("YYYY")}`;
    } else {
      return `${moment(date).subtract(1, "year").format("YYYY")}-${moment(
        date
      ).format("YYYY")}`;
    }
  };


  useEffect(() => {
    setIsExistSameSchoolYearSD(false);
    surveysDeployments.forEach((sd) => {
      if (sd.state !== SurveyDeploymentStateEnum.READY) {
        const surveyDeploymentYear = sd.deployment_name
          ? sd.deployment_name.split(" ")[0]
          : "";

        if (surveyDeploymentYear === getCurrentSession()) {
          setIsExistSameSchoolYearSD(true);
        }

        if (
          parseInt(surveyDeploymentYear.split("-")[0]) <
            parseInt(getCurrentSession().split("-")[0]) &&
          sd.state === SurveyDeploymentStateEnum.IN_PROGRESS &&
          Object.values(sd.responses).length
        ) {
          setIsExistSameSchoolYearSD(true);
        }
      }
    });
  }, [surveysDeployments]);


  const isStrategicPlanEnabled = useSelector(
    (s) => s.plans.activePlan?.district?.enabled_strategic_plan
  );

  const isDistrictActivePlan:any = useSelector(isDistrictPlan);

  const selectedSurveyDeployment = useSelector(
    (s) => s.surveysDeployment.selectedDistrictComarprisonSurvey
  );
  

  const comparisonItemsData=useSelector((s) => s.surveyReports.ComparisonDomainItemsData);
  const comparisonItemsDataLoading=useSelector((s) => s.surveyReports.loading.getComparisonDomainItemsData);


  const getSchoolDetail = (i: number) => {
    const sd = selectedSurveyDeployment?.[i];
    if (sd) {
      return (
              {
                deployment: sd?.deployment,
                administration: sd?.administration,
                month: sd?.month,
                year: sd?.year,
              }
      );
    }
    //  else {
    //   return (
    //     <Tooltip
    //       content={
    //         <div className="p-2">
    //           {intl.formatMessage({
    //             id: "app.district-comparison-table.total-mean-score.popover",
    //           })}
    //         </div>
    //       }
    //       position={Position.TOP}
    //       className="flex justify-center"
    //       popoverClassName="max-w-140"
    //       isOpen={totalMeanTooltipState}
    //     >
    //       <p className="text-center gap-2 text-lg font-semibold">
    //         {intl.formatMessage({
    //           id: "app.district-comparison-table.total-mean-score.title",
    //         })}
    //       </p>
    //     </Tooltip>
    //   );
    // }
  };

 
  // console.log("getSchoolDetail=>",getSchoolDetail(0).deployment)

  const selectedTabId: UserNotesType = useMemo(() => {
    return tabId &&
      [
        UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES,
        UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
        UserNotesType.DC_REPORTING_DISTRICT_COMPARISON,
        UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA,
        UserNotesType.DC_REPORTING_RESOURCES,
      ].includes(tabId as UserNotesType)
      ? (tabId as UserNotesType)
      : isDistrictActivePlan || !isDistrictActivePlan
      ? UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS
      : UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES;
  }, [tabId, isDistrictActivePlan]);

  const title = useMemo(() => {
    return intl.formatMessage({
      id: "app.titles.data-collection-and-reports",
    });
  }, []);
//  console.log("hello",surveyComparison[0].map((s)=>s.domain.name))

  const buttonText = useMemo(() => {
    return intl.formatMessage(
      {
        id: "app.survey-report.selected-domain.return-button.text",
      },
      {
        page:
          routeParams?.tabId === UserNotesType.DC_REPORTING_DISTRICT_COMPARISON
            ? intl.formatMessage({ id: "app.titles.district" })
            : "",
      }
    );
  }, [routeParams]);
  const comparisonReportType = useMemo(() => {
    return getUrlParam(REPORT_TYPE_PARAM, location.search);
  }, [location.search]);

  const handleReturnToMainReportClick = () => {
    const { tagId, ...params } = routeParams;
    history.push({
      pathname: generatePath(AuthRoutes.YourPlanData, {
        ...params,
        workspace: "data",
      } as any),
      search:
        comparisonReportType !== null
          ? `?${REPORT_TYPE_PARAM}=${comparisonReportType}`
          : "",
    });
  }







  let surveyComparisonData: DomainQuestionsComparison | DomainComparison[] = [];
  if (surveyComparison) {
    for (let i = 0; i < surveyComparison?.length; i++) {
      if ((surveyComparison[i] as DomainComparison[])?.length === undefined) {
        surveyComparisonData = surveyComparison[i];
        break;
      }
    }
  }

  const firstSurveyData = useMemo(() => surveyComparisonData, [
    surveyComparisonData,
  ]);
  

  const isItemLevelComparison = useMemo(
    () => isDomainQuestionsComparison(firstSurveyData),
    [firstSurveyData]
  );


  const {
    surveyDepsDemographics,
    domainSchoolsDomainComparisons = [],
    itemComparisons = [],
  } = useMemo(() => {
    const surveyDepsDemographics = _.chain(surveyComparison)
      .map((domainComparisons) =>
        getSurveyDemographicForComparison(domainComparisons)
      )
      .value();

    if (isItemLevelComparison) {
      const uniqDomainResponsesByCommonText = _.chain(
        surveyComparison as DomainQuestionsComparison[]
      )
        .flatMap((c) => c.responses)
        //.uniqBy((domainResponse) => domainResponse.common_text_id)
        .uniqBy(
          (domainResponse) => domainResponse && domainResponse.common_text_id
        )
        .value();

      const itemComparisons = _.chain(uniqDomainResponsesByCommonText)
        .map((domainResponse) => {
          const questionResponses = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) => {
                if (!!surveyDemographics?.length) {
                  let questionResponseData = surveyDemographics.map(
                    (demographic) => {
                      let responseData = (surveyComparison as DomainQuestionsComparison[])?.[
                        surveyDeploymentIndex
                      ]?.responses.find(
                        (r) =>
                          domainResponse &&
                          r.common_text_id === domainResponse.common_text_id
                      )?.respondents?.[demographic];
                      if (responseData?.is_deleted) responseData = undefined;
                      return { ...responseData, ...{ demographic } };
                    }
                  );
                  return questionResponseData;
                } else return [{ demographic: "" as SurveyDemographic }];
              }
            )
            .value();

          return {
            domainResponse: domainResponse,
            questionResponses: questionResponses,
          };
        })
        .filter((r) => {
          let alldeleted = true;
          for (let i = 0; i < r.questionResponses?.length; i++) {
            if (r.questionResponses[i]?.is_deleted === false)
              alldeleted = false;
          }
          if (alldeleted) return false;
          else return true;
        })
        .value();

      let newComparisonData = [...JSON.parse(JSON.stringify(itemComparisons))];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics?.length - 1]?.length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }

      newComparisonData = newComparisonData?.map((et: any) => {
        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.questionResponses))]
          : []; //[...et?.questionResponses];

        const midIndex: any = array?.length - lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array?.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart?.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }
        // CALCULATION OF MEAN SCORE ITEM LEVEL
        for (let i in secondPart) {
          secondPart[i].distribution = [];
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          let newArr: any = [];
          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].count = secondPart[i].count + firstPart[j].count;
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
            let total_with_count = 0;
            let total = 0;
            Object.keys(msC).map((key, index) => {
              // console.log(`${key}: ${msC[key?.toString()]}`);
              total_with_count = total_with_count +(msC[key?.toString()] * parseInt(key));
              total = total + msC[key?.toString()];
            });
            let ms = total_with_count / total;
            if (!isNaN(ms)) secondPart[i].mean_score = ms;
            else secondPart[i].mean_score = undefined;
          }
        }
        et.questionResponses = [...firstPart, ...secondPart];
        return et;
      });
      return {
        itemComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };
    } else {
      const uniqDomains = _.chain(surveyComparison as DomainComparison[][])
        //.flatMap((c) => c.map((d) => d.domain))
        .flatMap((c) => {
          if (!!c.length) return c.map((d) => d.domain);
          else return [];
        })
        .uniqBy((d) => d?.id)
        .value();

      const domainSchoolsDomainComparison = _.chain(uniqDomains)
        .map((domain) => {
          const domainComparisons = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) =>
                {
                  return surveyDemographics.map((demographic) => {
                  let responseData = (surveyComparison as DomainComparison[][])?.[
                    surveyDeploymentIndex
                  ]?.find((dC) => dC.domain.id === domain.id)?.respondents?.[
                    demographic
                  ];
                  return { ...responseData, ...{ demographic } };
                })}
            )
            .value();

          return {
            domain: domain,
            domainComparisons: domainComparisons,
          };
        })
        .sort((a, b) => {
          let anum = 0;
          let bnum = 0;
          a.domainComparisons.forEach((data) => {
            if (!!data?.mean_score) anum++;
          });
          b.domainComparisons.forEach((data) => {
            if (!!data?.mean_score) bnum++;
          });
          if (anum === bnum) {
            return a.domain.name < b.domain.name ? -1 : 1;
          }
          return anum < bnum ? 1 : -1;
        })
        .value();

      let newComparisonData = [
        ...JSON.parse(JSON.stringify(domainSchoolsDomainComparison)),
      ];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics?.length - 1]?.length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }
      newComparisonData = newComparisonData?.map((et: any) => {
        //DOMAIN LEVEL

        if (comparisonReportType == null) return et;

        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.domainComparisons))]
          : []; //[...et?.domainComparisons];

        const midIndex: any = array?.length - lastEleLength; //lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }

        // CALCULATION OF MEAN SCORE
        for (let i in secondPart) {
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          secondPart[i].distribution = [];
          let newArr: any = [];

          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].total_respondents = 0;
              secondPart[i].index = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }

            // debugger;
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].total_respondents =
                secondPart[i].total_respondents +
                (isNaN(firstPart[j].total_respondents) ? 0 :firstPart[j].total_respondents) ;
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
          }
          let total_with_count = 0;
          let total = 0;
          Object.keys(msC).map((key, index) => {
            total_with_count =
              total_with_count + msC[key?.toString()] * parseInt(key);
            total = total + msC[key?.toString()];
          });
          let ms = total_with_count / total;
          if (!isNaN(ms)) secondPart[i].mean_score = ms;
          else secondPart[i].mean_score = undefined; //undefined;
        }

        et.domainComparisons = [...firstPart, ...secondPart];
        return et;
      });

      return {
        domainSchoolsDomainComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };
    }
  }, [isItemLevelComparison, surveyComparison]);

  

const getItemsData=(surveyComparison:any)=>{

  const surveyDepsDemographics = _.chain(surveyComparison)
      .map((domainComparisons) =>
        getSurveyDemographicForComparison(domainComparisons)
      )
      .value();
      const uniqDomainResponsesByCommonText = _.chain(
        surveyComparison as DomainQuestionsComparison[]
      )
        .flatMap((c) => c.responses)
        //.uniqBy((domainResponse) => domainResponse.common_text_id)
        .uniqBy(
          (domainResponse) => domainResponse && domainResponse.common_text_id
        )
        .value();

      const itemComparisons = _.chain(uniqDomainResponsesByCommonText)
        .map((domainResponse) => {
          const questionResponses = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) => {
                if (!!surveyDemographics.length) {
                  let questionResponseData = surveyDemographics.map(
                    (demographic) => {
                      let responseData = (surveyComparison as DomainQuestionsComparison[])?.[
                        surveyDeploymentIndex
                      ]?.responses.find(
                        (r) =>
                          domainResponse &&
                          r.common_text_id === domainResponse.common_text_id
                      )?.respondents?.[demographic];
                      if (responseData?.is_deleted) responseData = undefined;
                      return { ...responseData, ...{ demographic } };
                    }
                  );
                  return questionResponseData;
                } else return [{ demographic: "" as SurveyDemographic }];
              }
            )
            .value();

          return {
            domainResponse: domainResponse,
            questionResponses: questionResponses,
          };
        })
        .filter((r) => {
          let alldeleted = true;
          for (let i = 0; i < r.questionResponses.length; i++) {
            if (r.questionResponses[i]?.is_deleted === false)
              alldeleted = false;
          }
          if (alldeleted) return false;
          else return true;
        })
        .value();

      let newComparisonData = [...JSON.parse(JSON.stringify(itemComparisons))];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics.length - 1].length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }

      newComparisonData = newComparisonData?.map((et: any) => {
        if (comparisonReportType == null) return et;
        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.questionResponses))]
          : []; //[...et?.questionResponses];

        const midIndex: any = array?.length - lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }
        // CALCULATION OF MEAN SCORE ITEM LEVEL
        for (let i in secondPart) {
          secondPart[i].distribution = [];
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          let newArr: any = [];
          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].count = secondPart[i].count + (isNaN(firstPart[j].count)? 0 :firstPart[j].count);
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
            let total_with_count = 0;
            let total = 0;
            Object.keys(msC).map((key, index) => {
              total_with_count =
                total_with_count + msC[key?.toString()] * parseInt(key);
              total = total + msC[key?.toString()];
            });
            let ms = total_with_count / total;
            if (!isNaN(ms)) secondPart[i].mean_score = ms;
            else secondPart[i].mean_score = undefined;
          }
        }
        et.questionResponses = [...firstPart, ...secondPart];
        return et;
      });
      return {
        itemComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };

}

 


  const DownloadExcelFile = () => {
 

  //Get the total reponsdents
  const on = surveyComparison.map((cd:any)=>(cd[0]?.respondents));

  const data = [
    [activePlan?.district?.name],// district name row 
    ['',..._.flatMapDeep(surveyDepsDemographics.map((sdd:any,ind:number)=> {return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedSurveyDeployment[ind]?.schoolName ?? '' :""}))}))], // schoolname row

    ['',..._.flatMapDeep(surveyDepsDemographics.map((sdd:any,ind:number)=> {
      
      const selectedDeployment =selectedSurveyDeployment[ind];
      
      return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedDeployment ?`${selectedDeployment?.deployment} Admin #${selectedDeployment?.administration}/${selectedDeployment?.month}, ${selectedDeployment?.year}` 
      :
      comparisonReportType && 'Total Mean Scores'
       :""}))
    }))], // deployment name row
    
    
    ['',..._.flatMapDeep(surveyDepsDemographics).map((s:any)=> intl.formatMessage({
      id: `app.filters.respondent-type.${s}`}))],// demographic name row

      ['Respondent Count',..._.flatMapDeep(surveyComparison.map((cd:any)=>(cd[0]?.respondents)).map((item:any,index:number)=>_.flatMapDeep(surveyDepsDemographics[index]).map((s:any)=>[item][0][s]?.total_respondents  ? [item][0][s]?.total_respondents:0))),
    
  ],// respondent count row
    
    ...domainSchoolsDomainComparisons.map((d:any)=>[d?.domain?.name,...d.domainComparisons.map((dc:any)=> dc?.mean_score?.toFixed(2)),])
  
  
  ];

  function getInitials(name: string): string {
    const matches = name.match(/(\b\S)?/g);
    return matches ? matches.join("") : "";
  }


  // Create a new workbook
  const workbook = XLSX.utils.book_new();

   // Convert the data to a worksheet
   const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Domain Page");

  domainSchoolsDomainComparisons.map((d:any)=> { 
 
   const matchedDomain =comparisonItemsData?.filter((ci:any)=> ci?.domain?.id === d?.domain?.id)
    const itemComparisonsData = getItemsData(matchedDomain?.length ===1 && comparisonReportType ? [...matchedDomain,...matchedDomain]:matchedDomain)
   
    const itemsData=[
      [d?.domain?.name], // domain name Row
      
      ['',..._.flatMapDeep(itemComparisonsData?.surveyDepsDemographics.map((sdd:any,ind:number)=> {return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedSurveyDeployment[ind]?.schoolName ?? '' :""}))}))], // schoolname row

      ['',..._.flatMapDeep(itemComparisonsData?.surveyDepsDemographics.map((sdd:any,ind:number)=> {
      
        const selectedDeployment =selectedSurveyDeployment[ind];
        
        return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedDeployment ?`${selectedDeployment?.deployment} Admin #${selectedDeployment?.administration}/${selectedDeployment?.month}, ${selectedDeployment?.year}` 
        :
        comparisonReportType && 'Total Mean Scores'
         :""}))
      }))], // deployment name row

      ['',..._.flatMapDeep(itemComparisonsData?.surveyDepsDemographics).map((s:any)=> intl.formatMessage({
        id: `app.filters.respondent-type.${s}`}))],// demographic name row

      ['Respondent Count',..._.flatMapDeep(surveyComparison.map((cd:any)=>(cd[0]?.respondents)).map((item:any,index:number)=>_.flatMapDeep(itemComparisonsData?.surveyDepsDemographics[index]).map((s:any)=>[item][0][s]?.total_respondents  ? [item][0][s]?.total_respondents:0)))],

      ...(itemComparisonsData?.itemComparisons).map((itc:any)=>[itc?.domainResponse?.common_text,...itc?.questionResponses.map((qr:any)=>qr?.mean_score?.toFixed(2))]) // item name row
    
    ] 
    // console.log(itemsData,"<<<<<<itemsData")
    
    const itemWorksheet = XLSX.utils.aoa_to_sheet(itemsData);

   return XLSX.utils.book_append_sheet(workbook,itemWorksheet , "Items-"+getInitials((d?.domain?.name).replaceAll("and","").toUpperCase()))
  }
  )
  // // Merge cells where needed
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } }, // Merge "Demo Data District 1"
    { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } }, // Merge "Mango School"
  ];

    
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary string
    const binaryString = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const buffer = new ArrayBuffer(binaryString?.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < binaryString?.length; i++) {
      view[i] = binaryString.charCodeAt(i) & 0xff;
    }
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExcel =()=>{
    const idsArray = domainSchoolsDomainComparisons?.map((d:any)=>selectedSurveyDeployment?.map((sd)=>{return {surveyDeploymentID:sd.surveyDeploymentId,school_id:sd.schoolId ,domain:d?.domain?.id}}))
  
    dispatch(getComparisonDomainItemsData.request(idsArray));
  
  }
  
  useEffect(() => {
  
    if(comparisonItemsDataLoading === false){
    DownloadExcelFile()
    }
  
    
  }, [comparisonItemsDataLoading]);
  
  useEffect(() => {
    if ( activePlan?.id ||  !addSD) {
      dispatch(
        getSurveysDeployment.request({
          planId:  activePlan?.id ,
       
        })
      );
    }
  }, [activePlan?.id, addSD]);


  const schoolNameDeploymentOptions:{school_name:string|undefined,deployment_name:string}[]= useMemo(() => {
    let surveyTableItems: {school_name:string|undefined,deployment_name:string}[] = [];
    surveysDeployments?.map((sd:any) => {
      let school: School | undefined;
      sd?.school_open_periods?.map((d:any) => {
        school = d.school;
      });
      surveyTableItems.push({
        school_name: school?.name,
        deployment_name:sd?.deployment_name
      });
    });
    return surveyTableItems;
  }, [surveysDeployments]);
  


  const schoolYearsOptions = useMemo(() => {
    const dateArray = ["07/01/2021"]; 
    // surveyDeploymentsBySchool.map((srd: any) => srd.start_date);
    const dateObjects: any = dateArray.map((dateStr) => new Date(dateStr));
  
    // Find the lowest and greatest dates
    const lowestDate = new Date(Math.min(...dateObjects));
    const greatestDate = new Date(Math.max(...dateObjects));
  
    // Create groups
    const startYear = lowestDate.getFullYear();
    const endYear = greatestDate.getFullYear()+20;
    const groups = [];
  
    for (let year = startYear; year <= endYear; year++) {
      const nextYear = year + 1;
      const group = `${year}-${nextYear}`;
      groups.push(group);
    }
  
    return groups;
  }, []);

  useEffect(() => {
    dispatch(getSurveys.request());
  }, []);


  const showExistSDDialog = (survey: Survey) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          dispatch(setSelectedSurvey(survey));
          dispatch(showSurveyDeploymentDialog());
          dispatch(hideConfirmDialog());
        },
        show: true,
        intent: "primary",
        icon: "info-sign",
        text: intl.formatMessage({
          id: "app.survey-deployment-dialog.exist-same-school-year",
        }),
        confirmButtonText: intl.formatMessage({
          id: "app.survey-deployment-dialog.exist-same-school-year.yes",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.no",
        }),
      })
    );
  };


  const showSurveyDeploymentDialogWithSelectedSurvey = (survey: Survey) => {
    if (!sdLoading) {
      if (isExistSameSchoolYearSD) {
        showExistSDDialog(survey);
      } else {
        dispatch(setSelectedSurvey(survey));
        dispatch(showSurveyDeploymentDialog());
      }
    } else if (loading) {
      if (isExistSameSchoolYearSD) {
        showExistSDDialog(survey);
      } else {
        dispatch(setSelectedSurvey(survey));
        dispatch(showSurveyDeploymentDialog());
      }
    }
  };

  const DownloadButton =activePlan?.district?.is_dummy_district && <div className="flex justify-end">

  <Button
            loading={comparisonItemsDataLoading}
             disabled={false}
             // className="absolute top-0 right-0"
             intent="primary"
             icon="download"
             title={intl.formatMessage({
               id: "app.survey-report.breadcrumb.download-spreadsheet",
             })}
             text={intl.formatMessage({
               id: "app.survey-report.breadcrumb.download-spreadsheet",
             })}
              onClick={handleDownloadExcel}
             // loading={loading}
           /> 
</div> 

if(selectedTabId === UserNotesType.DC_REPORTING_DISTRICT_COMPARISON){
return (
  <>
    <div className="teampage_container data-collection-report">
      <div className="breadcrumbs">
        <ul>
          <li>
            {" "}
            <a
              onClick={() =>
                history.push(
                  generatePath(AuthRoutes.YourPlan, {
                    workspace: "dashboard",
                  } as any)
                )
              }
            >
              {intl.formatMessage({ id: "app.titles.dashboard" })}
            </a>
          </li>
          <li>
            <span>/</span>
          </li>
          <a
            onClick={() =>
              history.push(
                generatePath(AuthRoutes.YourPlan, {
                  workspace: "data" as any,
                } as any)
              )
            }
          >
            {title}
          </a>
          <li>
            <span> /</span>
          </li>
          <a href="#">
            {comparisonReportType?
            intl.formatMessage({
              id: "app.district-comparison.comparison-report.label",
            })
            :
            intl.formatMessage({id:"app.survey-deployment.view-selected-Reports"})
 
          }
          </a>
        </ul>
      </div>

      {(!comparisonReportType || comparisonReportType) ?"": <div className="toolbar">
        <h1>Data Collection and Reports</h1>
      </div>}
      {routeParams.domainId &&
          routeParams?.tabId ===
            UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && (
            <>
              <div className="mt-4">
                    <Icon
                onClick={() => handleReturnToMainReportClick()}
                icon="arrow-left"
                iconSize={30}
                className="ml-5 cursor-pointer"
              />
                  <strong className="text-3xl ml-2">{selectedDomain?.name}</strong>
              </div>
             
             
            </>
          )}
      <DistrictComparisonTab DownloadButton={DownloadButton}/>
    </div>
  </>
);

}


const onSelectSchoolYears = (year: string, index: number, event: any) => {
  setSelectedYears((prevSelectedYears: any) => {
    if (prevSelectedYears?.find((y: any) => y === year)) {
      return prevSelectedYears.filter((item: any) => item !== year);
    } else {
      const newSelectedYears = [...prevSelectedYears, year];
      newSelectedYears.sort();
      return newSelectedYears;
    }
  });
}

  const onSelectSchoolDeployment = (school_name: string, index: number, event: any) => {
    setSelectedSchoolDeployment(prevSelectedSchoolDeployment => {
      const relatedDeployments = schoolNameDeploymentOptions.filter(
        (sd: any) => sd.school_name === school_name
      );
      const areAllSelected = relatedDeployments.every(deployment =>
        prevSelectedSchoolDeployment.find(
          y => y.school_name === deployment.school_name && y.deployment_name === deployment.deployment_name
        )
      );
  
      if (areAllSelected) {
        return prevSelectedSchoolDeployment.filter(
          item => item.school_name !== school_name
        );
      } else {
        const newSelections = relatedDeployments.filter(
          deployment => !prevSelectedSchoolDeployment.find(
            y => y.school_name === deployment.school_name && y.deployment_name === deployment.deployment_name
          )
        );
        return [...prevSelectedSchoolDeployment, ...newSelections];
      }
    });
  };
  
  const uniqueSchoolNames = Array.from(new Set(schoolNameDeploymentOptions.map((sd: any) => sd.school_name)));

return (
  <>
    {surveysDeployments?.length === 0 ? (
      <div className="teampage_container data-collection-report">
        <div className="breadcrumbs">
          <ul>
            <li>
              {" "}
              <a
                onClick={() =>
                  history.push(
                    generatePath(AuthRoutes.YourPlan, {
                      workspace: "dashboard",
                    } as any)
                  )
                }
              >
                {intl.formatMessage({ id: "app.titles.dashboard" })}
              </a>
            </li>
            <li>
              <span>/</span>
            </li>
            <li>{title}</li>
          </ul>
        </div>

        <div className="toolbar">
          <h1>Data Collection and Reports</h1>
          <ul>
          {(!isDistrictActivePlan && tabId ===  UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS) && <li onClick={handleShowScreencastTutorialDialog}>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7639 24.1671C6.14701 24.1671 0.763916 18.784 0.763916 12.1671C0.763916 5.55109 6.14701 0.167053 12.7639 0.167053C19.3808 0.167053 24.7639 5.55109 24.7639 12.1671C24.7639 18.784 19.3808 24.1671 12.7639 24.1671ZM18.2508 11.6705L10.2252 7.05994C9.69984 6.75805 9.27304 7.00277 9.27304 7.60689V16.8284C9.27304 17.4321 9.69929 17.6774 10.2252 17.3753L18.2508 12.7646C18.7762 12.4629 18.7766 11.9727 18.2508 11.6706V11.6705Z"
                    fill="#ED4141"
                  />
                </svg>
                <span>Screencast Tutorial</span>
              </a>
            </li> }
            <li>
              <a href="#">
                <InfoBar />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <Tabs 
          selectedTabId={selectedTabId}
          onChange={(tabId) => {
            history.push(
              generatePath(AuthRoutes.YourPlanData, {
                workspace: "data",
                tabId: tabId,
              } as any)
            );
          }}
          animate={true} 
          key={"vertical"} 
          vertical={false}
          >
            <Tab
              id={UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS}
              title="Survey Deployment"
              panel={
                <>
                  {!isDistrictActivePlan&&<div className="Message-box">
                    <p>
                      {intl.formatMessage({
                        id:
                          "app.teaming.survey-deployment-table-superintendent-district-climate-coordinator",
                      })}
                    </p>
                    <ul>
                  
                      {sortedArr?.map((g: string) => {     
                        return (
                          <li>{g === "K" || g === "PK" ? g : g?.slice(1)}</li>
                        );                   
                        
                      })}
                    </ul>
                  </div>}
                  <div className="total-Deploymnet">
                    <React.Fragment>
                      <h6></h6>
                      {hasDataCoordinatorAccess &&
                      !isDistrictActivePlan &&
                      !isTeamMember ? (
                        <div className="table-btn-group">
                          <a
                            href="https://platform.njschoolclimate.org/files/activity/MTE=/download/pdf/NJ+SCI+Survey+Administration+Guidelines+For+Data+Coordinators+PDF.pdf"
                            className="download-btn"
                            target="_blank"
                            //  onClick={() => toggleDrawer(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                            >
                              <path
                                d="M14.984 8.76687C14.6701 8.45292 14.1871 8.45292 13.8731 8.76687L9.69509 12.9449V1.44928C9.69509 1.01457 9.33283 0.652313 8.89812 0.652313C8.46342 0.652313 8.10116 1.01457 8.10116 1.44928V12.9449L3.94728 8.76687C3.63332 8.45292 3.15031 8.45292 2.83636 8.76687C2.5224 9.08083 2.5224 9.56384 2.83636 9.87779L8.36681 15.4083C8.43926 15.4807 8.53587 15.529 8.63247 15.5773C8.70492 15.6256 8.80152 15.6256 8.89812 15.6256C8.99473 15.6256 9.09133 15.6015 9.18793 15.5532C9.28453 15.5049 9.38113 15.4566 9.45358 15.3841L14.984 9.85364C15.2738 9.58799 15.2738 9.08083 14.984 8.76687Z"
                                fill="#195A94"
                              />
                              <path
                                d="M16.4574 19.9722H1.3392C0.904494 19.9722 0.542236 19.6099 0.542236 19.1752V14.0553C0.542236 13.6206 0.904494 13.2584 1.3392 13.2584C1.77391 13.2584 2.13617 13.6206 2.13617 14.0553V18.4024H15.6604V14.0553C15.6604 13.6206 16.0227 13.2584 16.4574 13.2584C16.8921 13.2584 17.2544 13.6206 17.2544 14.0553V19.1752C17.2302 19.6099 16.8921 19.9722 16.4574 19.9722Z"
                                fill="#195A94"
                              />
                            </svg>
                            {intl.formatMessage({id:"app.collect-data.administration-guidelines"})}
                          </a>

                          {isExistSameSchoolYearSD ? (
                            <Popover
                              popoverClassName={"w-20rem"}
                              interactionKind={"hover"}
                              content={
                                <div className="p-2">
                                  {intl.formatMessage({
                                    id:
                                      "app.surveys-table.columns.deploy-in-my-school-already-exist",
                                  })}
                                </div>
                              }
                            >
                              <button
                                className="nj-btn addBtn"
                                onClick={() =>
                                  showSurveyDeploymentDialogWithSelectedSurvey(
                                    surveys[0]
                                  )
                                }
                                style={{
                                  backgroundColor: "lightgrey",
                                  color: "grey",
                                  border: "grey",
                                }}
                                disabled={true}
                              >
                                {" "}
                                create nj sci deployment
                              </button>
                            </Popover>
                          ) : (
                            <button
                              className="nj-btn addBtn"
                              onClick={() =>
                                showSurveyDeploymentDialogWithSelectedSurvey(
                                  surveys[0]
                                )
                              }
                            >
                              {" "}
                              create nj sci deployment
                            </button>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  </div>
                </>
              }
            />
            <Tab
              id={UserNotesType.DC_REPORTING_RESOURCES}
              title={intl.formatMessage({ id: "app.titles.resources" })}
              panel={
                <ActivityResourcesTab
                  activityTag={ActivityTagEnum.DATA}
                  userNotesType={UserNotesType.DC_REPORTING_RESOURCES}
                />
              }
            />
          </Tabs>
        </div>
        <div className="no-datafound">
          <div className="notfound-message no-stragies-found">
            <div className="notfound-message_inner">
              <h3>
                {intl.formatMessage({
                  id: `app.teaming.survey-deployment-table.no-survey-deployed-${isDistrictActivePlan?"district":"school"}`,
                })}
              </h3>
              <p>
                {intl.formatMessage({
                  id:
                    `app.teaming.survey-deployment-table-create-NJSurvey-Deployment-Get-Started-${isDistrictActivePlan?"district":"school"}`,
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="teampage_container data-collection-report">
        <div className="breadcrumbs">
          <ul>
            <li>
              {" "}
              <a
                onClick={() =>
                  history.push(
                    generatePath(AuthRoutes.YourPlan, {
                      workspace: "dashboard",
                    } as any)
                  )
                }
              >
                {intl.formatMessage({ id: "app.titles.dashboard" })}
              </a>
            </li>
            <li>
              <span>/</span>
            </li>
            <li>{title}</li>
          </ul>
        </div>

        <div className="toolbar">
          <h1>Data Collection and Reports</h1>
          <ul>
            {/* <li>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7639 24.1671C6.14701 24.1671 0.763916 18.784 0.763916 12.1671C0.763916 5.55109 6.14701 0.167053 12.7639 0.167053C19.3808 0.167053 24.7639 5.55109 24.7639 12.1671C24.7639 18.784 19.3808 24.1671 12.7639 24.1671ZM18.2508 11.6705L10.2252 7.05994C9.69984 6.75805 9.27304 7.00277 9.27304 7.60689V16.8284C9.27304 17.4321 9.69929 17.6774 10.2252 17.3753L18.2508 12.7646C18.7762 12.4629 18.7766 11.9727 18.2508 11.6706V11.6705Z"
                    fill="#ED4141"
                  />
                </svg>
                <span>Learning Module</span>
              </a>
            </li> */}
            {(!isDistrictActivePlan && tabId ===  UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS) && <li onClick={handleShowScreencastTutorialDialog}>
              <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7639 24.1671C6.14701 24.1671 0.763916 18.784 0.763916 12.1671C0.763916 5.55109 6.14701 0.167053 12.7639 0.167053C19.3808 0.167053 24.7639 5.55109 24.7639 12.1671C24.7639 18.784 19.3808 24.1671 12.7639 24.1671ZM18.2508 11.6705L10.2252 7.05994C9.69984 6.75805 9.27304 7.00277 9.27304 7.60689V16.8284C9.27304 17.4321 9.69929 17.6774 10.2252 17.3753L18.2508 12.7646C18.7762 12.4629 18.7766 11.9727 18.2508 11.6706V11.6705Z"
                    fill="#ED4141"
                  />
                </svg>
                <span>Screencast Tutorial</span>
              </a>
            </li> }
            <li>
              <a href="#">
                <InfoBar />
              </a>
            </li>
          </ul>
        </div>

        <div>
          <Tabs
            selectedTabId={selectedTabId}
            onChange={(tabId) => {
              history.push(
                generatePath(AuthRoutes.YourPlanData, {
                  workspace: "data",
                  tabId: tabId,
                } as any)
              );
            }}
            animate={true}
            key={"vertical"}
            vertical={false}
          >
            <Tab
              id={UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS}
              title="Survey Deployment"
              panel={
                <>
                  <div className="StrategiesListFilter">
                    <div className="filterbar">
                      <span className="filter-icon">
                        <svg
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.10791 0.369736L6.60393 7.87166V15.3736L10.316 17.249V7.8713L16.812 0.369385H8.46013L0.10791 0.369736Z"
                            fill="#8B8B8B"
                          ></path>
                        </svg>
                      </span>
                   
                      <div className="filterCol">
                      <FormControl size="small" className="selectMargin" fullWidth>
                        <InputLabel htmlFor="school-year-multiple-checkbox-dates-label">
                          {intl.formatMessage({
                            id: "app.schools.select-school-years",
                          })}
                        </InputLabel>
                        <Select
                          id="school-year-multiple-checkbox-dates-label"
                          label="school-year-multiple-checkbox-dates"
                          multiple
                          value={selectedYears}
                          // onChange={handleMultiSurveyItemChange}

                          renderValue={(selected) =>
                            selectedYears
                              .map((item: any) => item.slice(0, item.length))
                              .join(", ")
                          }
                        >
                          <>
                            {/* <h6 className='ml-1'>
               School Years Selected({selectedYears.length})
                </h6> */}
                            <hr />
                            <div
                              style={{
                                height: "200px",
                                width: "auto",
                                overflowY: "scroll",
                              }}
                            >
                              <div className="planing-goal__button-group progressM__item-group">
                                <div className="popover-list-Selected-year">
                                  {schoolYearsOptions.map(
                                    (year: string, index: number) => {
                                      return (
                                        <div className="planing-goal__button-group progressM__item-group">
                                          <div className="popover-list-Selected-year">
                                            <MenuItem
                                              key={year}
                                              value={year}
                                              className="checkbox-list"
                                            >
                                              <Checkbox
                                                id={
                                                  "school-year-checkbox" + year
                                                }
                                                onClick={(event) =>
                                                  onSelectSchoolYears(
                                                    year,
                                                    index,
                                                    event
                                                  )
                                                }
                                                checked={
                                                  selectedYears.find(
                                                    (y: string) => y === year
                                                  )
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {year}
                                              </Checkbox>
                                            </MenuItem>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            {!!selectedYears.length && (
                              <div className="clear-btn">
                                <button onClick={() => setSelectedYears([])}>
                                  <Icon icon="cross" iconSize={16} />
                                  {intl.formatMessage({
                                    id: "app.titles.clear-all",
                                  })}
                                </button>
                              </div>
                            )}
                          </>
                        </Select>
                      </FormControl>
                      </div>
                     
                      {isDistrictActivePlan &&( <> 
                        <div className="filterCol">          
                      <FormControl size="small" className="selectMargin" fullWidth>
                        <InputLabel htmlFor="school-year-multiple-checkbox-dates-label">
                        {intl.formatMessage({
                            id: "app.schools.select-school",
                          })}
                        </InputLabel>
                        <Select
                          id="school-year-multiple-checkbox-dates-label"
                          label="school-year-multiple-checkbox-dates"
                          multiple
                          value={selectedSchoolDeployment}
                          // onChange={handleMultiSurveyItemChange}

                          renderValue={(selected) => {
                            const uniqueSelectedSchoolDeployment = Array.from(
                              new Set(selectedSchoolDeployment.map((item: any) => item?.school_name.slice(0, item.length)))
                            );
                            return uniqueSelectedSchoolDeployment.join(", ");
                          }}
                        >
                          <>
                           <hr />
                            <div
                              style={{
                                height: "fit-content",
                                maxHeight: '200px',
                                width: "auto",
                                overflowY: "scroll",
                              }}
                            >
                              <div className="planing-goal__button-group progressM__item-group">
                                <div className="popover-list-Selected-year">
                                {uniqueSchoolNames.map(
                                  (school_name: string, index: number) => {
                                    return (
                                      <>
                                        {school_name && 
                                        <div className="planing-goal__button-group progressM__item-group">
                                          <div className="popover-list-Selected-year">
                                            <MenuItem
                                              key={index}
                                              value={school_name}
                                              className="checkbox-list"
                                            >
                                              <Checkbox
                                                id={
                                                  "school-Deployment-checkbox" + school_name
                                                }
                                                onClick={(event) =>
                                                  onSelectSchoolDeployment(
                                                    school_name,
                                                    index,
                                                    event
                                                  )
                                                }
                                                checked={
                                                  schoolNameDeploymentOptions
                                                    .filter(deployment => deployment.school_name === school_name)
                                                    .every(deployment => 
                                                      selectedSchoolDeployment.find(
                                                        (y: any) => y?.school_name === deployment.school_name && y?.deployment_name === deployment.deployment_name
                                                      )
                                                    )
                                                }
                                              >
                                                {school_name}
                                              </Checkbox>
                                            </MenuItem>
                                          </div>
                                        </div>
                                        }
                                      </>
                                    );
                                  }
                                )}
                                </div>
                              </div>
                            </div>
                            {!!selectedSchoolDeployment.length && (
                              <div className="clear-btn">
                                <button onClick={() => setSelectedSchoolDeployment([])}>
                                  <Icon icon="cross" iconSize={16} />
                                  {intl.formatMessage({
                                    id: "app.titles.clear-all",
                                  })}
                                </button>
                              </div>
                            )}
                          </>
                        </Select>
                      </FormControl>
                    </div>
                      <div className="mt-3">   
                      <FormControl>
                        <Checkbox
                          large
                          size={40}
                          id={"only_generate_report_check" }
                          onClick={() =>setIsGenerateReportOnly(!isGenerateReportOnly)}
                          checked={isGenerateReportOnly}
                        >
                          {intl.formatMessage({id:"app.data-collection-report.survey-deployment.only-generate-report-check"})}
                        </Checkbox>
                      </FormControl>
                   
                        </div>
                        </>)}
              
                      </div>
                      </div>
                   
                  {!isDistrictActivePlan && (
                    <div className="Message-box">
                      <p>
                        {intl.formatMessage({
                          id:
                            "app.teaming.survey-deployment-table-superintendent-district-climate-coordinator",
                        })}
                      </p>
                      <ul>
                    
                        {sortedArr?.map((g: string) => {     
                           return (
                            <li>{g === "K" || g === "PK" ? g : g?.slice(1)}</li>
                          );                   
                          
                        })}
                      </ul>
                    </div>
                  )}
                  <div className="total-Deploymnet">
                    <React.Fragment>
                      <h6>
                        <span>({surveysDeployments?.length})</span>
                        {intl.formatMessage({id:"app.data-collection-report.survey-deployment.total-deployment"})}
                      </h6>
                      {hasDataCoordinatorAccess &&
                      !isDistrictActivePlan &&
                      !isTeamMember ? (
                        <div className="table-btn-group">
                          <a
                            href="https://platform.njschoolclimate.org/files/activity/MTE=/download/pdf/NJ+SCI+Survey+Administration+Guidelines+For+Data+Coordinators+PDF.pdf"
                            className="download-btn"
                               target="_blank"
                            //  onClick={() => toggleDrawer(true)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="20"
                              viewBox="0 0 18 20"
                              fill="none"
                            >
                              <path
                                d="M14.984 8.76687C14.6701 8.45292 14.1871 8.45292 13.8731 8.76687L9.69509 12.9449V1.44928C9.69509 1.01457 9.33283 0.652313 8.89812 0.652313C8.46342 0.652313 8.10116 1.01457 8.10116 1.44928V12.9449L3.94728 8.76687C3.63332 8.45292 3.15031 8.45292 2.83636 8.76687C2.5224 9.08083 2.5224 9.56384 2.83636 9.87779L8.36681 15.4083C8.43926 15.4807 8.53587 15.529 8.63247 15.5773C8.70492 15.6256 8.80152 15.6256 8.89812 15.6256C8.99473 15.6256 9.09133 15.6015 9.18793 15.5532C9.28453 15.5049 9.38113 15.4566 9.45358 15.3841L14.984 9.85364C15.2738 9.58799 15.2738 9.08083 14.984 8.76687Z"
                                fill="#195A94"
                              />
                              <path
                                d="M16.4574 19.9722H1.3392C0.904494 19.9722 0.542236 19.6099 0.542236 19.1752V14.0553C0.542236 13.6206 0.904494 13.2584 1.3392 13.2584C1.77391 13.2584 2.13617 13.6206 2.13617 14.0553V18.4024H15.6604V14.0553C15.6604 13.6206 16.0227 13.2584 16.4574 13.2584C16.8921 13.2584 17.2544 13.6206 17.2544 14.0553V19.1752C17.2302 19.6099 16.8921 19.9722 16.4574 19.9722Z"
                                fill="#195A94"
                              />
                            </svg>
                            {intl.formatMessage({id:"app.collect-data.administration-guidelines"})}
                          </a>

                          {isExistSameSchoolYearSD ? (
                            <Popover
                              popoverClassName={"w-20rem"}
                              interactionKind={"hover"}
                              content={
                                <div className="p-2">
                                  {intl.formatMessage({
                                    id:
                                      "app.surveys-table.columns.deploy-in-my-school-already-exist",
                                  })}
                                </div>
                              }
                            >
                              <button
                                className="nj-btn addBtn"
                                onClick={() =>
                                  showSurveyDeploymentDialogWithSelectedSurvey(
                                    surveys[0]
                                  )
                                }
                                style={{
                                  backgroundColor: "lightgrey",
                                  color: "grey",
                                  border: "grey",
                                }}
                                disabled={true}
                              >
                                {" "}
                                create nj sci deployment
                              </button>
                            </Popover>
                          ) : (
                            <button
                              className="nj-btn addBtn"
                              onClick={() =>
                                showSurveyDeploymentDialogWithSelectedSurvey(
                                  surveys[0]
                                )
                              }
                            >
                              {" "}
                              create nj sci deployment
                            </button>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  </div>
                  <SurveysDeploymentTable
                    selectedSchoolDeployment={selectedSchoolDeployment}
                    isGenerateReportOnly={isGenerateReportOnly}
                    selectedYears={selectedYears}
                    darkHeader
                    isTeamPlanTable
                    id={SURVEYS_DEPLOYMENT_TABLE}
                  />
                </>
              }
            />

            <Tab
              id={UserNotesType.DC_REPORTING_RESOURCES}
              title={intl.formatMessage({ id: "app.titles.resources" })}
              panel={
                <ActivityResourcesTab
                  activityTag={ActivityTagEnum.DATA}
                  userNotesType={UserNotesType.DC_REPORTING_RESOURCES}
                />
                //   <ActivityResourcesTab
                //   userNotesType={
                //     isDistrictActivePlan
                //       ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
                //       : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
                //   }
                // />
              }
            />
          </Tabs>
        </div>
      </div>
    )}

    <SurveyDeploymentDialog isTeamPlanDialog />
    <CreateCustomDataSetDialog />
    <CreateDatasetFromFileDialog />
    <SurveyUpsertDialog />
  </>
);








};





export default CollectDataPage;



  {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}

       
        {/* <div>
          <WorkspaceHeader
            title={title}
            userNotesType={selectedTabId as UserNotesType}
          />

          
//           {routeParams.domainId &&
//             routeParams?.tabId ===
//               UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && (
//               <>
//                 <div className="flex justify-between mt-4">
//                   <ReturnButton
//                     text={buttonText}
//                     onClick={handleReturnToMainReportClick}
//                   />
//                 </div>
//                 <div className="flex items-center justify-between py-4">
//                   <div className="flex gap-2 items-center">
//                     <strong className="text-3xl">{selectedDomain?.name}</strong>
//                   </div>
//                 </div>
//               </>
//             )}

//           {!isDistrictActivePlan &&
//             selectedTabId !== UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS && (
//               <div className="pt-0 p-5 pb-0">
//                 {intl.formatMessage({ id: "app.collect-data.info" })}
//               </div>
//             )}
// <div className="teampage_container__tab">
//           <Tabs
//             selectedTabId={selectedTabId}
//             onChange={(tabId) => {
//               history.push(
//                 generatePath(AuthRoutes.YourPlanData, {
//                   workspace: "data",
//                   tabId: tabId,
//                 } as any)
//               );
//             }}
//             renderActiveTabPanelOnly
//           >
//             {!isDistrictActivePlan && (
//               <Tab
//                 id={UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES}
//                 className="text-xl"
//                 panel={<AdministrationGuidelinesTab />}
//               >
//                 {intl.formatMessage({
//                   id: "app.collect-data.administration-guidelines",
//                 })}
//               </Tab>
//             )}
//             <Tab
//               id={UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS}
//               className="text-xl"
//               panel={<SurveyDataTab />}
//             >
//               {intl.formatMessage({
//                 id: "app.collect-data.survey-deployments",
//               })}
//             </Tab>
//             {isDistrictActivePlan && (
//               <Tab
//                 id={UserNotesType.DC_REPORTING_DISTRICT_COMPARISON}
//                 className="text-xl"
//                 panel={<DistrictComparisonTab />}
//                 disabled={!selectedSurveyDeployment.length}
//               >
//                 <Popover
//                   popoverClassName="w-25"
//                   interactionKind={"hover"}
//                   disabled={!!selectedSurveyDeployment.length}
//                   content={
//                     <div className={"p-3"}>
//                       {intl.formatMessage(
//                         {
//                           id:
//                             "app.collect-data.district-comparison.tab-disabled.popover",
//                         },
//                         { i: (chunks) => <i>{chunks}</i> }
//                       )}
//                     </div>
//                   }
//                 >
//                   {intl.formatMessage({
//                     id: "app.collect-data.district-comparison",
//                   })}
//                 </Popover>
//               </Tab>
//             )}
//             {/* {!isDistrictActivePlan && isStrategicPlanEnabled && (
//               <Tab
//                 id={UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA}
//                 className="text-xl"
//                 panel={<AddAdditionalDataForm isReadonly={isTeamMember} />}
//               >
//                 {intl.formatMessage({
//                   id: "app.collect-data.additional-data",
//                 })}
//               </Tab>
//             )} */}
//             <Tab
//               id={UserNotesType.DC_REPORTING_RESOURCES}
//               title={intl.formatMessage({ id: "app.titles.resources" })}
//               panel={
//                 <ActivityResourcesTab
//                   activityTag={ActivityTagEnum.DATA}
//                   userNotesType={UserNotesType.DC_REPORTING_RESOURCES}
//                 />
//                 //   <ActivityResourcesTab
//                 //   userNotesType={
//                 //     isDistrictActivePlan
//                 //       ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
//                 //       : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
//                 //   }
//                 // />
//               }
//             />
             
//   <Button
//             disabled={false}
//             className="absolute top-0 right-0"
//             intent="primary"
//             icon="download"
//             title={intl.formatMessage({
//               id: "app.survey-report.breadcrumb.download-spreadsheet",
//             })}
//             text={intl.formatMessage({
//               id: "app.survey-report.breadcrumb.download-spreadsheet",
//             })}
//              onClick={handleDownloadExcel}
//             // loading={loading}
//           />

//           </Tabs>

   










//           </div>
//         </div>
//       </div>

//       <SurveyDeploymentDialog isTeamPlanDialog />
//       <CreateCustomDataSetDialog />
//       <CreateDatasetFromFileDialog />
//       <SurveyUpsertDialog />
//       <CoordinateSurveyAdministrationDialog
//         isOpen={!isFirstTimeDialogWatched && isDistrictActivePlan}
//         onClose={handleCoordinateSurveyAdministrationClose}
//       />


//     </>

    
//   );  

// return (
//   <>
//     <Helmet>
//       <title>{title}</title>
//     </Helmet>

//     <div className="teampage_container">
//       <div>
//         <WorkspaceHeader
//           title={title}
//           userNotesType={selectedTabId as UserNotesType}
//         />

//         {routeParams.domainId &&
//           routeParams?.tabId ===
//             UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && (
//             <>
//               <div className="flex justify-between mt-4">
//                 <ReturnButton
//                   text={buttonText}
//                   onClick={handleReturnToMainReportClick}
//                 />
//               </div>
//               <div className="flex items-center justify-between py-4">
//                 <div className="flex gap-2 items-center">
//                   <strong className="text-3xl">{selectedDomain?.name}</strong>
//                 </div>
//               </div>
//             </>
//           )}

//         {!isDistrictActivePlan &&
//           selectedTabId !== UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS && (
//             <div className="pt-0 p-5 pb-0">
              {/* {intl.formatMessage({ id: "app.collect-data.info" })} */}
            // </div>
          // /)}
        // <div className="teampage_container__tab">
        //   <div className="flex items-center justify-between">

        //   <Tabs
        //     selectedTabId={selectedTabId}
        //     onChange={(tabId) => {
        //       history.push(
        //         generatePath(AuthRoutes.YourPlanData, {
        //           workspace: "data",
        //           tabId: tabId,
        //         } as any)
        //       );
        //     }}
        //     renderActiveTabPanelOnly
        //   >
        //     {!isDistrictActivePlan && (
        //       <Tab
        //         id={UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES}
        //         className="text-xl"
        //         panel={<AdministrationGuidelinesTab />}
        //       >
        //         {intl.formatMessage({
        //           id: "app.collect-data.administration-guidelines",
        //         })}
        //       </Tab>
        //     )}
        //     <Tab
        //       id={UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS}
        //       className="text-xl"
        //       panel={<SurveyDataTab />}
        //     >
        //       {intl.formatMessage({
        //         id: "app.collect-data.survey-deployments",
        //       })}
        //     </Tab>
        //     {isDistrictActivePlan ? (
        //       <Tab
        //         id={UserNotesType.DC_REPORTING_DISTRICT_COMPARISON}
        //         className="text-xl"
        //         panel={<DistrictComparisonTab />}
        //         disabled={!selectedSurveyDeployment.length}
        //       >
        //         <Popover
        //           popoverClassName="w-25"
        //           interactionKind={"hover"}
        //           disabled={!!selectedSurveyDeployment.length}
        //           content={
        //             <div className={"p-3"}>
        //               {intl.formatMessage(
        //                 {
        //                   id:
        //                     "app.collect-data.district-comparison.tab-disabled.popover",
        //                 },
        //                 { i: (chunks) => <i>{chunks}</i> }
        //               )}
        //             </div>
        //           }
        //         >
        //           {intl.formatMessage({
        //             id: "app.collect-data.district-comparison",
        //           })}
        //         </Popover>
        //       </Tab>
        //     )
        //   :
        //   (
        //     <Tab
        //       id={UserNotesType.DC_REPORTING_DISTRICT_COMPARISON}
        //       className="text-xl"
        //       panel={<DistrictComparisonTab />}
        //       disabled={!selectedSurveyDeployment.length}
        //     >
        //       <Popover
        //         popoverClassName="w-25"
        //         interactionKind={"hover"}
        //         disabled={!!selectedSurveyDeployment.length}
        //         content={
        //           <div className={"p-3"}>
        //            {intl.formatMessage({id:"app.school-surveys-comparison.tab.info"},
        //              { i: (chunks) => <i>{chunks}</i> })}
        //           </div>
        //         }
        //       >
        //         {intl.formatMessage({
        //             id: "app.collect-data.school-comparison",
        //           })}
        //       </Popover>
        //     </Tab>
        //   )
          
          // }
            {/* {!isDistrictActivePlan && isStrategicPlanEnabled && (
              <Tab
                id={UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA}
                className="text-xl"
                panel={<AddAdditionalDataForm isReadonly={isTeamMember} />}
              >
                {intl.formatMessage({
                  id: "app.collect-data.additional-data",
                })}
              </Tab>
            )} */}
            {/* <Tab
              id={UserNotesType.DC_REPORTING_RESOURCES}
              title={intl.formatMessage({ id: "app.titles.resources" })}
              panel={
                <ActivityResourcesTab
                  activityTag={ActivityTagEnum.DATA}
                  userNotesType={UserNotesType.DC_REPORTING_RESOURCES}
                />
                //   <ActivityResourcesTab
                //   userNotesType={
                //     isDistrictActivePlan
                //       ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
                //       : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
                //   }
                // />
              }
            />
             
             {selectedTabId === UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && isDistrictActivePlan && (
              !isItemLevelComparison ? 
              (comparisonItemsDataLoading ?
              <Tag className="absolute top-0 right-0" intent="primary" large>
              Allow a few minutes for download
            </Tag> :<Button
                disabled={false}
                className="absolute top-0 right-0"
                intent="primary"
                icon="download"
                title={intl.formatMessage({
                  id: "app.survey-report.breadcrumb.download-spreadsheet",
                })}
                text={intl.formatMessage({
                  id: "app.survey-report.breadcrumb.download-spreadsheet",
                })}
                onClick={handleDownloadExcel}
              />)
              :""
            )}


          </Tabs>





      
         
          </div>
        </div> */}